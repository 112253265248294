import { FC, useMemo, useState } from 'react';
import { NewsSummary, PressNewsDetail, Provider } from '@nwa/graphql';
import { Text } from '../../Text';
import { DraggableItemList } from './DraggableItemList';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { SvgIcon } from '../../SvgIcon';
import { Down, Hamburger, Remove, Right, CircleArrow } from '../../../svg';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { filterChoicesSelector } from '../../../redux/filterChoices/selectors';
import { OtherNewsItemList } from './OtherNewsItemList';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface NewsDraggableCardProps {
  currentPressDraggable?: PressNewsDetail[];
  category: string;
  onClickTitle: (id: string, category: string, provider: Provider) => void;
  onClickRemoveCategory: (id: string) => void;
  onClickRemoveNews: (id: string, category: string) => void;
  index: number;
  pressAi: boolean;
  otherNews?: NewsSummary[];
  onClickViewOthers: (categoryId: string) => void;
  onClickAddNews: (id: string) => void;
  fetchMore: (categoryId: string) => Promise<any>;
  hasMore: {
    [key: string]: boolean;
  };
}

export const NewsDraggableContainerCategory: FC<NewsDraggableCardProps> = ({
  currentPressDraggable,
  category,
  onClickRemoveCategory,
  onClickRemoveNews,
  onClickTitle,
  index,
  pressAi,
  otherNews,
  onClickViewOthers,
  onClickAddNews,
  fetchMore,
  hasMore,
}) => {
  const { categories } = useSelector(filterChoicesSelector);

  const categoryId = useMemo(() => {
    const categoryObj = categories.find((c) => c.name === category);
    return categoryObj ? categoryObj.id : '';
  }, [categories, category]);

  const [collapsed, setCollapsed] = useState(false);

  const [viewOtherNews, setViewOtherNews] = useState(false);

  const handleViewMore = () => {
    if (viewOtherNews) {
      setViewOtherNews(false);
    } else {
      setViewOtherNews(true);
      if (!otherNews || otherNews?.length === 0) onClickViewOthers(categoryId);
    }
    //console.log('viewOtherNews', viewOtherNews);
  };
  //console.log('currentPressDraggable: ', currentPressDraggable);

  const _fetchMore = () => {
    return fetchMore(categoryId);
  };

  return (
    <Draggable
      draggableId={category ? category : 'Nessuna categoria'}
      index={index}
      key={'draggable_cat' + category ? category : 'Nessuna categoria'}
    >
      {(providedDrag) => (
        <div
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
          className="flex-col items-center justify-between rounded-lg mt-1 p-4 border border-gray-300"
          style={{
            ...providedDrag.draggableProps.style,
            backgroundColor: '#FAFAFA',
            minHeight: collapsed ? '' : '120px',
          }}
        >
          <div className="flex flex-row w-full items-center justify-between ">
            <div className="flex flex-row items-center">
              <SvgIcon
                svg={
                  collapsed ? (
                    <Right className="h-5 w-5" />
                  ) : (
                    <Down className="h-5 w-5" />
                  )
                }
                pointer={true}
                onClick={() => setCollapsed(!collapsed)}
              />
              <Text
                text={category}
                skipTranslation={true}
                className="font-semibold "
                style={{
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                }}
              />
              {category !== t('Nessuna categoria') && (
                <SvgIcon
                  svg={<Remove className="h-5 w-5 ml-2" />}
                  pointer={true}
                  onClick={() => onClickRemoveCategory(category)}
                />
              )}
            </div>
            <div>
              <SvgIcon svg={<Hamburger className="h-5 w-5" />} />
            </div>
          </div>
          <Droppable
            droppableId={category ? category : 'Nessuna categoria'}
            type="news"
          >
            {(providedDropNews) => (
              <div
                className={
                  collapsed
                    ? 'pl-4'
                    : 'py-3 px-2 border border-[#C7C7C7] bg-[#EFEFEF] rounded-lg mt-2 w-full h-full'
                }
                ref={providedDropNews.innerRef}
                {...providedDropNews.droppableProps}
              >
                {!collapsed &&
                currentPressDraggable &&
                currentPressDraggable.length > 0
                  ? currentPressDraggable.map((news, i) => (
                      <DraggableItemList
                        key={news.id}
                        index={i}
                        id={news.id!}
                        title={news.title}
                        newsId={news.newsId!}
                        onClickRemove={(id: string) =>
                          onClickRemoveNews(id, news.category || '')
                        }
                        onClickTitle={(id: string) =>
                          onClickTitle(id, news.category || '', news.provider)
                        }
                        provider={news.provider}
                      />
                    ))
                  : !collapsed && (
                      <div className="flex justify-center items-center h-full">
                        <Text text="Trascina qui gli articoli" />
                      </div>
                    )}
                {providedDropNews.placeholder}
              </div>
            )}
          </Droppable>
          {!collapsed &&
            pressAi &&
            category !== t('Nessuna categoria') &&
            categories.length > 0 && (
              <div className="flex flex-col m-2 mt-4">
                <div
                  className="flex flex-row m-2 mt-4 items-center cursor-pointer rounded-md p-2 transition-all"
                  onClick={handleViewMore}
                >
                  <CircleArrow
                    className={`h-5 w-5   ${
                      viewOtherNews ? 'rotate-0' : 'rotate-[-90deg]'
                    }`}
                  />

                  <Text text="Visualizza altro" className="ml-2" />
                </div>
                {viewOtherNews && (
                  <>
                    {otherNews && otherNews.length > 0 ? (
                      <div
                        className="flex h-96 w-full flex-col scrollbar px-2 rounded-lg mt-2 overflow-y-auto"
                        id="containerOther"
                      >
                        <InfiniteScroll
                          dataLength={otherNews.length} //This is important field to render the next data
                          next={_fetchMore}
                          hasMore={hasMore[categoryId]}
                          loader={
                            <p className="text-center">
                              <Text text="Caricamento" className="font-bold" />
                              ...
                            </p>
                          }
                          endMessage={
                            <p className="text-center">
                              <Text
                                text="Fine risultati"
                                className="font-bold"
                              />
                            </p>
                          }
                          scrollableTarget={'containerOther'}
                        >
                          {otherNews.map((news) => (
                            <OtherNewsItemList
                              key={news.id}
                              id={news.id}
                              title={news.title}
                              onClickTitle={(id: string) =>
                                onClickTitle(
                                  id,
                                  news.category?.name || '',
                                  news.provider as Provider
                                )
                              }
                              onClickAdd={(id: string) => {
                                onClickAddNews(id);
                              }}
                              provider={news.provider as Provider}
                            />
                          ))}
                        </InfiniteScroll>
                      </div>
                    ) : (
                      <></>
                      // spinner
                      /*
                    <div className="flex items-center justify-center p-4">
                      <div className="w-32 h-32 border-l-4 border-[#4285F4] rounded-full animate-twSpin animate-infinite"></div>
                    </div>
                    */
                    )}
                  </>
                )}
              </div>
            )}
        </div>
      )}
    </Draggable>
  );
};
